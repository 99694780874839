@font-face {
	font-family: 'Glyphicons Halflings';
	src: url('../../../fonts/vendor/bootstrap-sass/bootstrap/glyphicons-halflings-regular.eot');
	src: url('../../../fonts/vendor/bootstrap-sass/bootstrap/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'),
	url('../../../fonts/vendor/bootstrap-sass/bootstrap/glyphicons-halflings-regular.woff') format('woff'),
	url('../../../fonts/vendor/bootstrap-sass/bootstrap/glyphicons-halflings-regular.ttf') format('truetype'),
	url('../../../fonts/vendor/bootstrap-sass/bootstrap/glyphicons-halflings-regular.svg#glyphicons-halflingsregular') format('svg');
}

@import 'components/stepwizard';
@import 'components/login_modal';
@import 'components/card';
@import 'components/calendar';
@import 'components/loader';

.control-label.required {
	position: relative;

	&:after {
		color: #c00;
		content: ' *';
		margin-left: 3px;
		position: absolute;
	}
}

.margin-bottom-sm {
	margin-bottom: 5px;
}

.margin-bottom-md {
	margin-bottom: 15px;
}

.entry-content a.btn {
	color: #333;
}

.entry-content a.btn-primary {
	color: #fff;
}

.entry-header.row {
	margin-bottom: 20px;
}

.costs-summary {
	display: flex;
	margin-top: 25px;
	padding-top: 25px;
	border-top: 1px solid #e3e3e3;

	.form-group {
		margin-bottom: 5px;
	}

	.align-bottom {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
	}
}

.new-existing {
	background: #f6f6f6;
	padding: 15% 10%;

	.glyphicon {
		font-size: 6em;
		text-align: center;
		display: block;
		color: #aaa;
		margin-bottom: 25px;
	}
}

.customer-details-check,
.customer-details {
	margin-bottom: 20px;
}

table.booking-details--passengers td {
	vertical-align: middle !important;
}
