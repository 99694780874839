.calendar-key {
    font-weight: bold;
    ul {
        display: inline-block;
        margin-top: 10px;
        li {
            font-weight: bold;
            color: black;
            display: inline-block;
            padding: 5px 10px;
            margin-left: 0;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.calendar {
    table {
        margin: 0;
    }
}

//TODO - Reset table styles across subdomains because native stylesheets mess with them.