.confirmation-payment-check {
    position: relative;

    p {
        font-weight: bold;
        text-align: center;
        padding-top: 100px;
    }
}

@keyframes loader-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader {
    position: absolute;
    margin: -18px 0 0 -18px;
    border: 3.6px solid lightskyblue;
    box-sizing: border-box;
    overflow: hidden;
    width: 36px;
    height: 36px;
    left: 50%;
    top: 50%;
    animation: loader-spin 2s linear infinite reverse;
    filter: url(#goo);
    box-shadow: 0 0 0 1px lightskyblue inset;
    &:before {
        content: "";
        position: absolute;
        animation: loader-spin 2s cubic-bezier(0.59, 0.25, 0.4, 0.69) infinite;
        background: lightskyblue;
        transform-origin: top center;
        border-radius: 50%;
        width: 150%;
        height: 150%;
        top: 50%;
    }
}